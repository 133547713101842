import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CardComponent } from '../card/card.component';
import { HapticTapDirective } from '../../../directives/haptic-tap.directive';

@Component({
  selector: 'app-lib-dialog',
  imports: [
    ButtonComponent,
    CardComponent,
    HapticTapDirective,
  ],
  templateUrl: './lib-dialog.component.html',
  styleUrl: './lib-dialog.component.scss',
})
export class LibDialogComponent {
  /**
   * if true, will display Ok button and Cancel button. If false, will only display a close button
   */
  @Input() hasOkCancelButton = true;
  @Input() hasCloseButton = true;
  /**
   * set to true to display the dialog. set to false, to close
   */
  @Input() show = false;
  /**
   * the title text to display
   */
  @Input() titleText = 'Edit';
  /**
   * the message to display
   */
  @Input() messageText?: string;
  /**
   * the text for ok-button
   */
  @Input() okText = 'Ok';
  /**
   * the text for cancel-button
   */
  @Input() cancelText = 'Cancel';
  /**
   * the text for close-button
   */
  @Input() closeText = 'Close';
  /**
   * the cancel/close button is clicked
   */
  @Output() cancelClicked = new EventEmitter();
  /**
   * the ok button (primary) is clicked
   */
  @Output() okClicked = new EventEmitter();
  @ViewChild('dialog') private dialog?: ElementRef<HTMLDialogElement>;

  public ngOnChanges(changes: SimpleChanges): void {
    if ('show' in changes && !changes['show'].isFirstChange()) {
      if (this.show) {
        this.dialog?.nativeElement.showModal();
      } else {
        this.close();
      }
    }
  }

  protected cancelClick(): void {
    this.cancelClicked.emit();
  }

  protected okClick(): void {
    this.okClicked.emit();
  }

  private close(): void {
    this.dialog?.nativeElement.close();
  }
}
