<dialog class="lib-dialog" #dialog>
  <div class="lib-dialog__wrapper">
    <app-card [mainContentOnly]="true">
      <div class="lib-dialog__header glob-center">
        <span class="glob-font-24 glob-font-w900">{{ titleText }}</span>
        @if (hasCloseButton) {
          <button hapticTap class="lib-dialog__header-close" (click)="cancelClick()">
            <span class="material-symbols-outlined">close</span>
          </button>
        }
      </div>
      <div class="lib-dialog__content glob-center glob-font-16">
        @if (messageText) {
          {{ messageText }}
        }
        <ng-content></ng-content>
      </div>
      <div class="lib-dialog__footer">
        @if (hasOkCancelButton) {
          <app-button (click)="okClick()" level="primary" [fullWidth]="true" [marginBottom]="true">{{ okText }}</app-button>
          <app-button level="secondary" (click)="cancelClick()" [fullWidth]="true">{{ cancelText }}</app-button>
        } @else {
          <app-button (click)="cancelClick()">{{ closeText }}</app-button>
        }
      </div>
    </app-card>
  </div>
</dialog>
